import { useState } from 'react';

export interface SelectInputProperties {
  fieldId?: string;
  handleInputChange?: (inputEvent: Event) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
  selected?: string | number;
}

function SelectInput(props: SelectInputProperties) {
  const [selected, setSelected] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChange(e: any) {
    if (props.handleInputChange) {
      props.handleInputChange(e);
    }

    setSelected(e.target.selectedIndex);
  }

  return (
    <select name={props.fieldId} id={props.fieldId} className="ui-select" onChange={handleChange}>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.options?.map((opt: any, i: number) => {
          if (i === selected) {
            return (
              <option value={opt.value} selected={true}>
                {opt.label}
              </option>
            );
          }

          return <option value={opt.value}>{opt.label}</option>;
        })
      }
    </select>
  );
}

export default SelectInput;
