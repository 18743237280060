import React, { useEffect, useState, useRef } from 'react';
import {
  withAuthenticator,
  AmplifyContainer,
  AmplifyFormSection,
  AmplifyPicker,
  AmplifySelect,
  AmplifyLabel,
  AmplifyStrike,
  AmplifyToast,
  AmplifyLink,
} from '@aws-amplify/ui-react';
import './App.css';
import { Auth, Storage } from 'aws-amplify';
import { v4 } from 'uuid';

import { capitalize } from './utils/string.formatters';
import FileTypeSelector from './components/FileTypeSelector';

export const DOMAIN_NOT_SELECTED = 'not-selected';
const UPLOAD_NOT_STARTED = 'not-started';
const UPLOAD_SUCCESS = 'success';
const UPLOAD_ERROR = 'error';
const DEFAULT_FILE_TYPE = 'other';
const DEFAULT_FILE_VALUE = { name: 'No file has been selected' } as File;
const DEFAULT_ERROR_MESSAGE = 'Error occurred during upload of the file';
const DEFAULT_SUCCESS_MESSAGE = 'File has been uploaded';

function App() {
  const [file, setFile] = useState(DEFAULT_FILE_VALUE);
  const [domains, setDomains] = useState([{ label: 'No domains', value: DOMAIN_NOT_SELECTED }]);
  const [domain, setDomain] = useState(DOMAIN_NOT_SELECTED);
  const [uploadState, setUploadState] = useState(UPLOAD_NOT_STARTED);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);
  const [successMessage, setSuccessMessage] = useState(DEFAULT_SUCCESS_MESSAGE);
  const [fileType, setFileType] = useState(DEFAULT_FILE_TYPE);
  const hideTooltipTimeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  const setDomainsValue = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const domainsValue = user.signInUserSession.accessToken.payload['cognito:groups'];

    if (domainsValue === undefined || domainsValue === '') {
      return;
    }

    setDomains(domainsValue.map((domainResult: string) => ({ label: capitalize(domainResult), value: domainResult })));
    setDomain(domainsValue[0]);
  };

  useEffect(() => {
    setDomainsValue();
  }, []);

  const validateFile = async (file: File) => {
    if (file.size === 0) throw new Error('You are not allowed to upload empty file');
  };

  const parseFileName = (fileName: string) => {
    const name = fileName.substring(0, fileName.lastIndexOf('.'));

    return fileType === DEFAULT_FILE_TYPE ? `${name}-other` : name;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (e: any) => {
    const file = e?.target?.files[0];

    if (file === undefined) return;

    try {
      await validateFile(file);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrorMessage(e.message);
      setUploadState(UPLOAD_ERROR);
      setFile(DEFAULT_FILE_VALUE);
      return;
    }

    setFile(file);
    e.target.value = '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (e: any) => {
    e.preventDefault();
    if (domain === DOMAIN_NOT_SELECTED) {
      setFile(DEFAULT_FILE_VALUE);
      return;
    }

    if (!file.name || file.name === DEFAULT_FILE_VALUE.name) return;

    const fileName = fileType === DEFAULT_FILE_TYPE ? file.name : fileType;

    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    const name = parseFileName(fileName);

    try {
      await Storage.put(`${domain}/${name}-${v4()}.${extension}`, file);
      setUploadState(UPLOAD_SUCCESS);
    } catch (e) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      setUploadState(UPLOAD_ERROR);
    }
    setSuccessMessage(`File ${file.name} has been uploaded`);
    setFile(DEFAULT_FILE_VALUE);

    hideTooltipTimeoutRef.current = setTimeout(onCloseInfoToast, 4000);
  };

  useEffect(() => {
    return () => {
      if (hideTooltipTimeoutRef.current) {
        clearTimeout(hideTooltipTimeoutRef.current);
      }
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeDomain = async (e: any) => {
    setDomain(e?.target?.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLogout = async (e: any) => {
    await Auth.signOut();
    window.location.reload();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCloseInfoToast = async () => {
    setUploadState('not-started');
  };

  const onChangeFileType = (value: string) => {
    setFileType(value);
  };

  return (
    <AmplifyContainer>
      {domain === DOMAIN_NOT_SELECTED && <AmplifyToast>You don't have any domains selected</AmplifyToast>}
      {uploadState === UPLOAD_ERROR && <AmplifyToast handleClose={onCloseInfoToast}>{errorMessage}</AmplifyToast>}
      {uploadState === UPLOAD_SUCCESS && <AmplifyToast handleClose={onCloseInfoToast}>{successMessage}</AmplifyToast>}
      <AmplifyFormSection
        headerText="Upload a file"
        secondaryFooterContent="Upload a file to selected domain"
        handleSubmit={submit}
        loading={domain === DOMAIN_NOT_SELECTED}
      >
        <div className="signout">
          <AmplifyLink onClick={onLogout} className="signout-button">
            Sign out
          </AmplifyLink>
        </div>
        <div className="amplify-row">
          <div className="amplify-row-element-50">
            <AmplifyLabel htmlFor="domain">Select domain</AmplifyLabel>
          </div>
          <div className="amplify-select-domain">
            <AmplifySelect id="domain" options={domains} handleInputChange={onChangeDomain}></AmplifySelect>
          </div>
        </div>
        <FileTypeSelector onChangeValueHandler={onChangeFileType} domain={domain} />
        <div className="amplify-row">
          <div className="amplify-row-element-50">
            <AmplifyLabel htmlFor="picker">{file.name}</AmplifyLabel>
          </div>
          <div className="amplify-row-element-50">
            <AmplifyPicker id="picker" inputHandler={onChange}></AmplifyPicker>
          </div>
        </div>

        <AmplifyStrike></AmplifyStrike>
      </AmplifyFormSection>
    </AmplifyContainer>
  );
}

export default withAuthenticator(App);
