import { AmplifyLabel } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

import { File, FilesApiClient } from '../apiClients/FilesApiClient';
import { DOMAIN_NOT_SELECTED } from '../App';
import SelectInput from './SelectInput';

export interface FileTypeSelectorProperties {
  onChangeValueHandler?: (value: string) => void;
  domain: string;
}

const FILE_TYPES_DEFAULT_VALUE = [{ label: 'Other', value: 'other' }];

function FileTypeSelector(props: FileTypeSelectorProperties) {
  const [fileTypes, setFileTypes] = useState(FILE_TYPES_DEFAULT_VALUE);
  const apiClient = new FilesApiClient();

  const setFileTypesValue = async () => {
    if (props.domain === DOMAIN_NOT_SELECTED) {
      return;
    }

    setFileTypes(FILE_TYPES_DEFAULT_VALUE);

    const files = await apiClient.getFiles(props.domain);
    const entries = files.map((file: File) => {
      return { label: file.name, value: file.fileFormat };
    });

    const types = entries.sort((a, b) => a.label.localeCompare(b.label));
    setFileTypes([...FILE_TYPES_DEFAULT_VALUE, ...types]);
  };

  useEffect(() => {
    setFileTypesValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domain]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFile = (e: any) => {
    if (props.onChangeValueHandler) {
      props.onChangeValueHandler(e?.target?.value);
    }
  };

  return (
    <div className="amplify-row">
      <div className="amplify-row-element-50">
        <AmplifyLabel htmlFor="fileType">Select file type</AmplifyLabel>
      </div>
      <div className="amplify-select-domain">
        <SelectInput fieldId="fileType" options={fileTypes} handleInputChange={onChangeFile}></SelectInput>
      </div>
    </div>
  );
}

export default FileTypeSelector;
