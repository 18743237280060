import { Auth } from 'aws-amplify';
import axios, { AxiosInstance } from 'axios';

export interface File {
  name: string;
  fileFormat: string;
}

export class FilesApiClient {
  constructor(
    private readonly client: AxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BAAS_API_URL,
    }),
  ) {}

  async getFiles(domain: string): Promise<File[]> {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

    const result = await this.client.get(`/v1/files/${domain}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  }
}
